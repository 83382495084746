import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

export class CertificatesSection {
	constructor(selector) {
		this.$container = selector;

		if (!this.$container) return;

		this.init();
	}

	init() {
		this.$sliderContainer = this.$container.querySelector('.swiper');

		if (this.$sliderContainer) {
			new Swiper(this.$sliderContainer, {
				modules: [Navigation],
				slidesPerView: 'auto',
				spaceBetween: 16,
				slidesOffsetBefore: 16,
				slidesOffsetAfter: 16,
				rewind: true,
				navigation: {
					nextEl: this.$container.querySelector('.js-swiper-next'),
					prevEl: this.$container.querySelector('.js-swiper-prev'),
				},
				breakpoints: {
					640: {
						slidesPerView: 'auto',
						spaceBetween: 16,
						slidesOffsetBefore: 20,
						slidesOffsetAfter: 20,
					},
					1280: {
						slidesPerView: 5,
						spaceBetween: 30,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
				},
			});
		}
	}
}
