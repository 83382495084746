import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

export class BrandsSection {
	constructor(selector) {
		this.$container = selector;

		if (!this.$container) return;

		this.init();
	}

	init() {
		this.$sliderContainer = this.$container.querySelector('.swiper');
		this.$sliderSlides = this.$container.querySelectorAll('.swiper-slide');
		this.$next = this.$container.querySelector('.js-swiper-next');
		this.$prev = this.$container.querySelector('.js-swiper-prev');

		if (this.$sliderContainer) {
			this.swiper = new Swiper(this.$sliderContainer, {
				modules: [Navigation],
				slidesPerView: 'auto',
				initialSlide: this.$sliderSlides.length / 2 - 4,
				spaceBetween: 16,
				slidesOffsetBefore: 16,
				slidesOffsetAfter: 16,
				rewind: true,
				breakpoints: {
					1280: {
						slidesPerView: 6,
						spaceBetween: 36,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
					1480: {
						slidesPerView: 6,
						spaceBetween: 56,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
					1680: {
						slidesPerView: 6,
						spaceBetween: 96,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
				},
			});

			this.toggleDisabled(this.$next, !this.swiper.allowSlideNext);
			this.toggleDisabled(this.$prev, !this.swiper.allowSlidePrev);

			this.swiper.on('resize', () => {
				this.toggleDisabled(this.$next, !this.swiper.allowSlideNext);
				this.toggleDisabled(this.$prev, !this.swiper.allowSlidePrev);
			});

			this.$next.addEventListener('click', this.nextHeandler.bind(this));
			this.$prev.addEventListener('click', this.prevHeandler.bind(this));
		}
	}

	nextHeandler() {
		const count = this.swiper.activeIndex + this.swiper.params.slidesPerView;
		if (count < this.swiper.slides.length) {
			this.swiper.slideTo(count);
		} else {
			this.swiper.slideTo(0);
		}
	}

	prevHeandler() {
		const count = this.swiper.activeIndex - this.swiper.params.slidesPerView;
		if (this.swiper.activeIndex === 0) {
			this.swiper.slideTo(this.swiper.slides.length - this.swiper.params.slidesPerView);
		} else if (count < 0) {
			this.swiper.slideTo(0);
		} else {
			this.swiper.slideTo(count);
		}
	}

	toggleDisabled($button, key) {
		$button.classList.toggle('swiper-button-lock', key);
	}
}
