import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

export class OffersSection {
	constructor(selector) {
		this.$container = selector;

		if (!this.$container) return;

		this.init();
	}

	init() {
		this.$sliderArray = this.$container.querySelectorAll('.swiper');

		this.$sliderArray.forEach(($slider) => {
			new Swiper($slider, {
				modules: [Navigation],
				slidesPerView: 'auto',
				spaceBetween: 16,
				slidesOffsetBefore: 16,
				slidesOffsetAfter: 16,
				navigation: {
					nextEl: this.$container.querySelector('.js-swiper-next'),
					prevEl: this.$container.querySelector('.js-swiper-prev'),
				},
				breakpoints: {
					640: {
						slidesPerView: 'auto',
						spaceBetween: 16,
						slidesOffsetBefore: 20,
						slidesOffsetAfter: 20,
					},
					1280: {
						slidesPerView: 4,
						spaceBetween: 30,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
				},
			});
		});
	}
}
