import '@/app/app.css';
import './main.css';

// import { POINTS } from '@/api/request/index';

import { Maps } from '@/entities/maps/maps';
import { BrandsSection } from '@/widgets/section/brands-section/brands-section';
import { OffersSection } from '@/widgets/section/offers-section/offers-section';
import { ReviewsSection } from '@/widgets/section/reviews-section/reviews-section';
import { CertificatesSection } from '@/widgets/section/certificates-section/certificates-section';
import { initCerificateModal } from '@/features/certificates/certificate-modal/certificate-modal';
import { AlsoBuy } from '@/features/also-buy/also-buy';

import '@/app/app';

window.app.Maps = Maps;

window.addEventListener('DOMContentLoaded', () => {
	// POINTS().then((response) => {
	// 	new Maps(document.querySelector('.js-demo-map'), {
	// 		popover: true,
	// 		points: response.data,
	// 	});
	// });

	document.querySelectorAll('.js-brands-section').forEach((element) => new BrandsSection(element));
	document.querySelectorAll('.js-offers-section').forEach((element) => new OffersSection(element));
	document.querySelectorAll('.js-reviews-section').forEach((element) => new ReviewsSection(element));
	document.querySelectorAll('.js-certificates-section').forEach((element) => new CertificatesSection(element));
	document.querySelectorAll('.js-also-buy').forEach((element) => new AlsoBuy(element));
	initCerificateModal();
});
